// noinspection DuplicatedCode

import { CurrencyIcon } from '../../../components'
import { AccountOverviewRowWrapper } from './AccountOverview.styled'

import { tr } from 'mmfintech-commons'

interface AccountOverviewRowProps {
  account: {
    id: number
    currencyCode: string
    balance: number
    name: string
    permission?: string
  }
}

export const AccountOverviewRow = ({ account }: AccountOverviewRowProps) => {
  const { currencyCode, balance, name, permission } = account

  return (
    <AccountOverviewRowWrapper>
      <div className='cell'>
        <CurrencyIcon currency={currencyCode} width='3.4rem' height='3.4rem' className='card mr-1 currency-icon' />
      </div>
      <div className='cell'>
        <span className='title'>
          {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.CURRENCY', 'Currency')}
        </span>
        <span>{currencyCode}</span>
      </div>
      <div className='cell'>
        <span className='title'>
          {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.ACCOUNT_NAME', 'Account Name')}
        </span>
        <span>{name}</span>
      </div>
      <div className='cell'>
        <span className='title'>
          {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.PERMISSIONS', 'Permissions')}
        </span>
        <span>
          {permission === 'WRITER'
            ? tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.FULL.TITLE', 'Full')
            : tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.VIEW_ONLY', 'View Only')}
        </span>
      </div>
      <div className='cell'>
        <span className='title'>
          {tr('FRONTEND.USERS.EXPENSE_MANAGEMENT.DASHBOARD.ACCOUNT_OVERVIEW.BALANCE', 'Balance')}
        </span>
        <span>{balance}</span>
      </div>
    </AccountOverviewRowWrapper>
  )
}
