import cn from 'classnames'

import { CurrencyIconWrapper } from './CurrencyIcon.styled'

export const CurrencyIcon = ({ currency, width = '3rem', height = '3rem', className = undefined }) => {
  return (
    <CurrencyIconWrapper
      width={width}
      height={height}
      className={cn(className, {
        [currency?.replace(/\./g, '-')]: true
      })}
    />
  )
}
